import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { claimDailyRewards } from '../../api/auth';
import { handleErrors } from '../../core/helpers';
import useFormattedAsset from '../../hooks/useFormattedAsset';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchAccount } from '../../store/reducers/accountSlice';
import Button from '../common/button';
import Dialog from '../common/dialog';
import ButtonLoader from '../common/loaders/buttonLoader';
import Typography from '../common/typography';

import DailyRewardImage from '../../assets/DailyRewardImage.png';

import styles from './DialogDailyRewards.module.scss';

const DialogDailyRewards = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const rewardShedule = useAppSelector(state => state.settings.settings.dailyRewardsSchedule);
  const currentStreak = useAppSelector(state => state.account.account.currentDailyRewardStreak);

  const dispatch = useAppDispatch();

  const rewardAsset = useMemo(() => {
    let day = rewardShedule.days.find((item) => item.number === currentStreak);
    return day!.assets[0];
  }, [currentStreak, rewardShedule.days]);

  const formattedAsset = useFormattedAsset(rewardAsset.assetId, rewardAsset.amount);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await claimDailyRewards();
      await dispatch(fetchAccount());
      toast(`You've got ${formattedAsset.amount} ${formattedAsset.name}`);
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, formattedAsset.amount, formattedAsset.name]);

  return (
    <Dialog title='Daily reward'>
      <div className={styles.dialog__content}>
        <div className={styles.amount__wrapper}>
          <img src={DailyRewardImage} alt='img' width={220} height={220}></img>
          <Typography variant='title-large' color='white' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', display: 'flex', justifyContent: 'center' }}>{`${formattedAsset.amount} ${formattedAsset.name}`}</Typography>
        </div>
        <div className={styles.info__wrapper}>
          <Typography variant="subheadline1" color="primary">Congratulation!</Typography>
          <Typography variant="title2" color="white" style={{ textAlign: 'center' }}>
            You just earned a reward!
          </Typography>
          <Typography variant="subheadline2" color="secondary" style={{ display: 'inline-block', wordBreak: 'break-word' }}>The sum of the prize increases proportionally to your daily logins to the app.</Typography>
          <Button
            onClick={handleClick}
            disabled={isLoading}
            endIcon={isLoading ? <ButtonLoader /> : null}
          >
            CLAIM YOUR COINS
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DialogDailyRewards;
