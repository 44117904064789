import { useMemo } from "react";
import { useAppSelector } from "../store/hooks";
import { LinkType } from "../types/types";

const tg = window.Telegram.WebApp;
const shareUrl = 'https://t.me/share/url';
const iniviteText = 'Grow your tree and save the planet.';

const useReferralLink = (type: LinkType) => {
  const miniAppUrl = useAppSelector(state => state.settings.settings.social.miniAppUrl);
  return useMemo(() => {
    if (type === LinkType.Copy) {
      return `${miniAppUrl}?startapp=ref${tg.initDataUnsafe.user.id}`;
    }
    return `${shareUrl}?url=${miniAppUrl}?startapp=ref${tg.initDataUnsafe.user.id}&text=${encodeURIComponent(iniviteText)}`
  }, [miniAppUrl, type]);
}

export default useReferralLink;