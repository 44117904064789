
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { IProcessTask, IPromoTaskItem, LinkType, PromoTaskType } from '../../../types/types';
import TaskItem from '../taskItem';

import { ReactComponent as IdentificationIcon } from '../../../assets/tasks/IdentificationIcon.svg';
import { ReactComponent as ShareStoryIcon } from '../../../assets/tasks/ShareStoryIcon.svg';
import useReferralLink from '../../../hooks/useReferralLink';

import StoriesImage from '../../../assets/StoriesImage.jpg';

const tg = window.Telegram?.WebApp;

const taskIcon = {
  [PromoTaskType.ChangeUsername]: <IdentificationIcon />,
  [PromoTaskType.ShareStory]: <ShareStoryIcon />,
}

interface IProps {
  task: IPromoTaskItem,
  onClick: (task: IProcessTask) => void,
}

const TaskPromoItem = ({ task, onClick }: IProps) => {
  const formattedAsset = useFormattedAsset(null, task.reward);
  const referralLink = useReferralLink(LinkType.Copy);
  const text = `I just levelled up on Hmdf game and earned more real tokens🥳. Join me here ${referralLink}`;

  const handleShareToStory = useCallback(async () => {
    try {
      if (tg.platform === 'tdesktop' || tg.platform === 'macos') {
        throw new Error('');
      }
      // const isPremium = tg.initDataUnsafe?.user?.is_premium;
      // const options = isPremium ? { widget_link: referralLink, text } : { text };
      const options = { text };
      await tg.shareToStory(
        StoriesImage,
        options
      );
      return true;
    } catch (err: any) {
      toast.error('Please, use Your phone to share!');
      return false;
    }
  }, [text]);

  const handleStart = useCallback(async () => {
    try {
      if (task.settings.taskType === PromoTaskType.ShareStory) {
        const success = await handleShareToStory();
        if (!success) {
          return;
        }
      }
      const res = await processTask(task.id.toString());
      if (res.status === 'RewardClaimed') {
        toast(`You've got ${formattedAsset.amount} ${formattedAsset.name}`);
      }
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
    }
  }, [onClick, task.id, formattedAsset.amount, formattedAsset.name, handleShareToStory, task.settings.taskType]);

  const handleVerify = useCallback(async () => {
    try {
      const res = await processTask(task.id.toString());
      if (res.status === 'RewardClaimed') {
        toast(`You've got ${formattedAsset.amount} ${formattedAsset.name}`);
      }
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
    }
  }, [onClick, task.id, formattedAsset.amount, formattedAsset.name]);

  const handleClick = useCallback(() => {
    if (task.operation === 'Start') {
      return handleStart();
    }
    if (task.operation === 'Verify') {
      return handleVerify();
    }
  }, [handleStart, handleVerify, task.operation]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <TaskItem type={task.type} title={task.title} status={task.status} leftIcon={taskIcon[task.settings.taskType]} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
    </div>
  );
}

export default TaskPromoItem;
