import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors, openLink } from '../../../core/helpers';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { IProcessTask, ISubscribeTaskItem, SubscribeTaskPlatform } from '../../../types/types';
import TaskItem from '../taskItem';

import { ReactComponent as TelegramIcon } from '../../../assets/tasks/TelegramIcon.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/tasks/TwitterIcon.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/tasks/InstagramIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/tasks/YoutubeIcon.svg';

const taskIcon = {
  [SubscribeTaskPlatform.Twitter]: <TwitterIcon />,
  [SubscribeTaskPlatform.Telegram]: <TelegramIcon />,
  [SubscribeTaskPlatform.Instagram]: <InstagramIcon />,
  [SubscribeTaskPlatform.Youtube]: <YoutubeIcon />
}

interface IProps {
  task: ISubscribeTaskItem,
  onClick: (task: IProcessTask) => void,
}

const TaskSubscribeItem = ({ task, onClick }: IProps) => {
  const formattedAsset = useFormattedAsset(null, task.reward);

  const handleStart = useCallback(async () => {
    try {
      const res = await processTask(task.id.toString());
      if (res.status === 'RewardClaimed') {
        toast(`You've got ${formattedAsset.amount} ${formattedAsset.name}`);
      }
      onClick(res);
      openLink(task.settings.link);
    } catch (err: any) {
      handleErrors(err);
    }
  }, [onClick, task.id, task.settings.link, formattedAsset.amount, formattedAsset.name]);

  const handleVerify = useCallback(async () => {
    try {
      const res = await processTask(task.id.toString());
      if (res.status === 'RewardClaimed') {
        toast(`You've got ${formattedAsset.amount} ${formattedAsset.name}`);
      }
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
      openLink(task.settings.link);
    }
  }, [onClick, task.id, formattedAsset.amount, formattedAsset.name, task.settings.link]);

  const handleClick = useCallback(() => {
    if (task.operation === 'Start') {
      return handleStart();
    }
    if (task.operation === 'Verify') {
      return handleVerify();
    }
  }, [handleStart, handleVerify, task.operation]);

  return (
    <TaskItem type={task.type} title={task.title} status={task.status} leftIcon={taskIcon[task.settings.platform]} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
  );
}

export default TaskSubscribeItem;
