export interface IWallet {
  getAddress(): string;
  getMnemonicPhrase(): string;
  signTransaction(transaction: any): any;
  createTransaction(amount: number, address: string, networkVersion: string, fee?: number): {
    amount: number;
    addressFrom: string;
    addressTo: string;
    pubKey: string;
    networkVersion: string;
    randomSalt: string;
    type: string;
    fee: number;
    assetId: string;
    data: any;
    signature: string;
    hash: string;
  }
};

interface ITransactionData {
  type: string,
  transacrtionType: string,
}

export interface ITransaction {
  networkVersion: string,
  dateTime: string,
  amount: number,
  fee: number,
  addressFrom: string,
  addressTo: string,
  pubKey: string,
  signature: string,
  hash: string,
  randomSalt: string,
  status: string,
  type: string,
  number: number,
  blockNumber: number,
  assetId: string,
  data: ITransactionData,
}

export interface IAsset {
  id: string,
  owner: string,
  name: string,
  description: string,
  decimals: number,
}

export interface ISendTransaction {
  networkVersion: string,
  amount: number,
  addressFrom: string,
  addressTo: string,
  pubKey: string,
  signature: string,
  hash: string,
  randomSalt: string,
}

export interface IAddressBalance {
  stable: number,
  pending: number,
  outgoing: number
}

export interface IReplenish {
  repeat: number,
  assets: {
    assetId: string,
    amount: number,
    refFactor: number,
    type: 'Balance' | 'Score',
    name: string,
  }[]
}

export interface INetworkSettings {
  networkVersion: string,
  decimals: number,
  minTransactionFee: number,
  payoutAddress?: string | null,
  hubUrl: string,
  explorerUrl: string,
}
export interface IGrow {
  maxGrowFactor: number,
}

export interface ISocialSettings {
  miniAppUrl: string;
  botUrl: string;
  communityUrl: string;
}

export interface IDayReward {
  number: number,
  assets: {
    assetId: null | string,
    amount: number
  }[]
}

export interface IApplicationSettings {
  replenish: IReplenish,
  wallet: {
    minimumReferrals: number,
  },
  networkSettings: INetworkSettings,
  social: ISocialSettings;
  tasks: ITask[],
  grow: IGrow,
  claimRewards: {
    repeat: number;
  },
  dailyRewardsSchedule: {
    days: IDayReward[]
  },
  ads: IAds;
}

export interface IAccount {
  id: number,
  username: string,
  referralsCount: number,
  walletAddress: string,
  lastReplenish: string,
  replenishCount: number,
  lastClaimRewards: string,
  score: number,
  rewards: number,
  hasDailyReward: boolean,
  currentDailyRewardStreak: number,
  lastAdView: string,
  lastAdClaimRewards: string,
}

export interface IReferal {
  id: string,
  username: string,
  score: number,
}

export interface IReferals {
  data: IReferal[],
  total: number,
}

type TaskSettings = ISubscribeTaskSettings | IWatchTaskSettings | IReferralTaskSettings | IProgressTaskSettings | IShareStorySettings | IChangeUsernameSettings;

export interface ITask {
  id: number,
  title: string,
  description: string,
  type: ITaskType,
  group: ITaskGroup,
  reward: 0,
  settings: TaskSettings,
  displayOrder: 0,
  expiresAt: string,
  isExpired: number
}

export interface IWatchTask extends ITask {
  settings: IWatchTaskSettings,
}
export interface ISubscribeTask extends ITask {
  settings: ISubscribeTaskSettings,
}
export interface IReferralTask extends ITask {
  settings: IReferralTaskSettings,
}
export interface IProgressTask extends ITask {
  settings: IProgressTaskSettings,
}

export interface IPromoTask extends ITask {
  settings: IShareStorySettings | IChangeUsernameSettings;
}

export type ITaskType = 'Subscribe' | 'Watch' | 'Referral' | 'Progress' | 'ChangeUsername' | 'ShareStory';
export type ITaskGroup = 'Subscribe' | 'Watch' | 'Referral' | 'Progress' | 'Promo';
export interface IUserTask {
  id: number,
  taskId: number,
  status: string,
  operation: string,
  updatedAt: string,
}

export interface IProcessTask {
  id: number,
  taskId: number,
  status: string,
  operation: string,
  updatedAt: string,
}

type PlatformType = "Telegram" | "X" | "Youtube" | "Instagram";
export interface ISubscribeTaskSettings {
  link: string,
  platform: PlatformType,
  taskType: "Subscribe",
}
export interface IWatchTaskSettings {
  link: string,
  platform: PlatformType,
  taskType: "Watch",
  protected?: boolean,
}
export interface IReferralTaskSettings {
  count: number,
  taskType: "Referral",
}

export interface IProgressTaskSettings {
  value: number,
  measure: "Score" | "ReplenishCount",
  taskType: "Progress",
}

export interface IChangeUsernameSettings {
  substring: string,
  taskType: 'ChangeUsername',
}

export interface IShareStorySettings {
  taskType: 'ShareStory',
}


export type IWatchTaskItem = IWatchTask & IUserTask;
export type ISubscribeTaskItem = ISubscribeTask & IUserTask;
export type IReferralTaskItem = IReferralTask & IUserTask;
export type IProgressTaskItem = IProgressTask & IUserTask;
export type IPromoTaskItem = IPromoTask & IUserTask;
export type ITaskItem = ITask & IUserTask;
export type IUserTaskMap = Map<number, IUserTask>;


export interface IRank {
  id: string,
  username: string,
  value: number,
  rank: number,
}

export interface IFetchedRanks {
  items: IRank[],
  ownRank: IRank,
}

export interface IStatistics {
  totalUsersCount: number,
  totalReferrals: number,
  totalReplenishCount: number,
  overallScore: number,
}

export enum RankType {
  Score = 'score',
  Referrals = 'referrals',
  ReplenishCount = 'replenishCount'
}

export enum SubscribeTaskPlatform {
  Telegram = 'Telegram',
  Twitter = 'X',
  Instagram = 'Instagram',
  Youtube = 'Youtube'
}

export enum PromoTaskType {
  ChangeUsername = 'ChangeUsername',
  ShareStory = 'ShareStory'
}

export interface IShowPromiseResult {
  done: boolean; // true if user watch till the end, otherwise false
  description: string; // event description
  state: 'load' | 'render' | 'playing' | 'destroy'; // banner state
  error: boolean; // true if event was emitted due to error, otherwise false
}

export interface IAds {
  repeat: number,
  blockId: number,
  debug: boolean,
  rewardAssets: {
    assetId: string,
    amount: number,
  }[]
}

export enum LinkType {
  Copy = 'copy',
  Share = 'share'
}