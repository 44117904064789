
import { IRank, RankType } from '../../../types/types';
import { useAppSelector } from '../../../store/hooks';
import Typography from '../../common/typography';

import styles from './RankItemSticky.module.scss';

import { ReactComponent as TreeScoreIcon } from '../../../assets/friends/TreeScoreIcon.svg';
import { ReactComponent as RankDropIcon } from '../../../assets/RankDropIcon.svg';
import { ReactComponent as RankReferralIcon } from '../../../assets/RankReferralIcon.svg';
import { useMemo } from 'react';
import useFormattedAsset from '../../../hooks/useFormattedAsset';

interface RankItemProps {
  rank: IRank,
  type: RankType,
  position: number,
  hidden: boolean,
}

const RankItemSticky = ({ rank, type, position, hidden = false }: RankItemProps) => {
  const assets = useAppSelector(state => state.settings.settings.replenish.assets);
  const scoreAsset = useMemo(() => {
    return assets.find((item) => item.type === 'Score');
  }, [assets]);
  const formattedAsset = useFormattedAsset(scoreAsset?.assetId || null, rank.value);
  const rankIcon = useMemo(() => {
    switch (type) {
      case 'score':
        return <TreeScoreIcon />;
      case 'referrals':
        return <RankReferralIcon />;
      case 'replenishCount':
        return <RankDropIcon />;
    }
  }, [type]);
  return (
    <div className={`${hidden ? `${styles.rank_item__wrapper} ${styles.hidden}` : styles.rank_item__wrapper}`}>
      <Typography variant='body1' color='white'>{position}</Typography>
      <div className='flex-break-word' style={{ width: '100%' }}>
        <Typography variant='body1' color='white'>{rank.username}</Typography>
      </div>
      <div className={styles.rank_score__wrapper}>
        {rankIcon}
        <Typography variant='subheadline2' color='white'>{type === 'score' ? formattedAsset.amount : rank.value}</Typography>
      </div>
    </div>
  );
}

export default RankItemSticky;
