import { repeatableFetch } from "../core/helpers";
import { IAccount, IApplicationSettings, IFetchedRanks, IProcessTask, IReferals, IReplenish, IStatistics, IUserTask } from "../types/types";

interface ISignatue {
  grantType: 'Signature',
  payload: string,
  walletAddress?: string,
}

interface IRefreshToken {
  grantType: 'RefreshToken',
  refreshToken: string,
}

const telegramApiUrl = process.env.REACT_APP_TELEGRAM_API_KEY;

export async function fetchData(url: string, config: any = {}, isRetry?: boolean): Promise<any> {
  config.headers = config.headers || {};
  config.headers["Content-Type"] = "application/json";
  config.headers["ngrok-skip-browser-warning"] = "true";

  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  try {
    const res = await fetch(url, config);
    if (res.ok) {
      return res;
    }
    if (res.status === 401) {
      if (isRetry) {
        removeAccessToken();
        removeRefreshToken();
        return window.location.reload();
      } else {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          await refreshTokens(refreshToken);
          return fetchData(url, config, true);
        }
      }
    }

    const error = await res.json();
    throw { errors: error.errors, statusCode: res.status };
  } catch (err: any) {
    throw err;
  }
}

export async function getToken(payload: ISignatue | IRefreshToken, isRetry?: boolean) {
  const url = `${telegramApiUrl}/api/oauth2/token`;
  const res = await fetchData(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  }, isRetry);
  const token = await res.json();
  setRefreshToken(token.refreshToken);
  return setAccessToken(token.accessToken);
}

export async function replenish(): Promise<IReplenish> {
  const url = `${telegramApiUrl}/api/balance/replenish`;
  const res = await fetchData(url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });
  return await res.json();
}

export async function getAuthSettings(): Promise<IApplicationSettings> {
  const url = `${telegramApiUrl}/api/settings`;
  const res = await repeatableFetch(url);
  return await res.json();
}

export async function getAccount(): Promise<IAccount> {
  const url = `${telegramApiUrl}/api/account`;
  const res = await fetchData(url);
  return await res.json();
}

export async function getReferrals(): Promise<IReferals> {
  const url = `${telegramApiUrl}/api/referrals`;
  const res = await fetchData(url);
  return await res.json();
}

export async function getStatistics(): Promise<IStatistics> {
  const url = `${telegramApiUrl}/api/statistics/summary`;
  const res = await fetchData(url);
  return await res.json();
}

export async function getRanks(type: string): Promise<IFetchedRanks> {
  const url = `${telegramApiUrl}/api/rankings/${type}`;
  const res = await fetchData(url);
  return await res.json();
}

export async function claimDailyRewards() {
  const url = `${telegramApiUrl}/api/rewards/daily/claims`;
  const res = await fetchData(url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });
  return await res.json();
}

export async function watchAdvertisement() {
  const url = `${telegramApiUrl}/api/ads/watch`;
  await fetchData(url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });
}

export async function claimRewards(): Promise<{ rewards: number }> {
  const url = `${telegramApiUrl}/api/referrals/claim-rewards`;
  const res = await fetchData(url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });
  return await res.json();
}

export async function getTasks(): Promise<IUserTask[]> {
  const url = `${telegramApiUrl}/api/tasks`;
  const res = await fetchData(url);
  return await res.json();
}

export async function processTask(id: string, payload?: string): Promise<IProcessTask> {
  const url = `${telegramApiUrl}/api/tasks/${id}/process`;
  const res = await fetchData(url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ payload }),
    });
  return await res.json();
}

async function refreshTokens(refreshToken: string) {
  try {
    await getToken({ grantType: 'RefreshToken', refreshToken }, true);
  } catch (e) {
    removeRefreshToken();
    removeAccessToken();
    window.location.reload();
    throw e;
  }
}

export function setAccessToken(token: string) {
  localStorage.setItem('accessToken', token);
}

export function getAccessToken() {
  return localStorage.getItem('accessToken');
}

export function removeAccessToken() {
  localStorage.removeItem('accessToken');
}

export function setRefreshToken(token: string) {
  localStorage.setItem('refreshToken', token);
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

export function removeRefreshToken() {
  localStorage.removeItem('refreshToken');
}