import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Divider from '../../components/common/divider';
import PageContentWrapper from '../../components/common/pageContentWrapper';
import PageWrapper from '../../components/common/pageWrapper';
import Typography from '../../components/common/typography';
import RankItem from '../../components/statistics/rankItem';
import RankItemSticky from '../../components/statistics/rankItemSticky';
import RankSlider from '../../components/statistics/rankSlider';
import List from '../../components/tasks/list';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchRanks, fetchStatistics } from '../../store/reducers/statisticsSlice';
import { IRank, RankType } from '../../types/types';

import styles from './Statistics.module.scss';

const titles = {
  [RankType.Referrals]: 'Total referrals summary',
  [RankType.Score]: 'Total growing summary',
  [RankType.ReplenishCount]: 'Total watering summary',
}

const maxRankListLength = 50;

const Statistics = () => {
  const ranks = useAppSelector(state => state.statistics.ranks);
  const [ranksType, setRanksType] = useState<RankType>(RankType.Score);
  const [inView, setInView] = useState(false);
  const rankList = useMemo(() => {
    const { ownRank, items } = ranks;
    if (!ownRank || !items) {
      return null;
    }
    const filteredRankList = items.filter((item) => item.id !== ownRank.id);
    filteredRankList.push(ownRank);
    filteredRankList.sort((a, b) => b.value - a.value || a.username.localeCompare(b.username));
    return filteredRankList.slice(0, maxRankListLength);
  }, [ranks]);

  const ownRankPosition = useMemo(() => {
    if (!rankList) {
      return ranks.ownRank.rank;
    }
    const position = rankList.findIndex((item) => item.id === ranks.ownRank.id);
    return position >= 0 ? position + 1 : ranks.ownRank.rank;
  }, [rankList, ranks.ownRank.id, ranks.ownRank.rank]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchRanks(ranksType));
  }, [dispatch, ranksType]);
  useEffect(() => {
    dispatch(fetchStatistics());
  }, [dispatch]);

  return (
    <PageWrapper>
      <>
        <div className={styles.slider__wrapper}>
          <Typography variant='title2' color='white'>{titles[ranksType]}</Typography>
          <div style={{ width: '100vw' }}>
            <RankSlider onSwipe={(type: RankType) => setRanksType(type)} />
          </div>
        </div>
        <PageContentWrapper style={{ marginTop: '-32px' }}>
          <>
            {!!rankList?.length && <List
              items={rankList}
              title='Ranking'
              renderItem={(item: IRank, index: number) =>
                <Fragment key={item.id}>
                  <RankItem rank={item} position={index + 1} type={ranksType} isOwn={item.id === ranks.ownRank.id} onIntersect={(inView) => setInView(inView)} />
                  {index !== rankList.length - 1 && <Divider />}
                </Fragment>}
            />}
            <RankItemSticky rank={ranks.ownRank} position={ownRankPosition} type={ranksType} hidden={inView} />
          </>
        </PageContentWrapper>
      </>
    </PageWrapper>
  );
}

export default Statistics;
